<!--
 * @Author: your name
 * @Date: 2024-03-12 20:23:12
 * @LastEditTime: 2024-08-15 15:47:08
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \web_BullyingPrevention\src\App.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div id="app">
    <router-view/>
        <!-- 一个div -->
        
  </div>
</template>

<script>
import SockJS from "sockjs-client"
import Stomp from 'stompjs'
export default {
  data () {
    return {
      schoolId:null,
      scoket:null,
      subscribe:null,
      schoolId:sessionStorage.getItem('schoolId'),
    }
  },
  mounted () {
   this.init()
  },
  methods: {
    init(){
            let that = this
            console.log("schoolId",this.schoolId)
            // var ws = new WebSocket(process.env.VUE_APP_RABBITMQ_STOMP)
            // var ws = new WebSocket('');
            let url = 'https://api.qingzhou.link/fbl/ws'
            if(sessionStorage.getItem('api')){
              url = 'https://api.qingzhou.link/fblv2/ws'
            }
            let soc = new SockJS(url);
            // this.scoket = soc
            // Init Client
            let client = Stomp.over(soc);
            this.scoket = client
            // SockJS does not support heart-beat: disable heart-beats
            client.heartbeat.outgoing = 5000; // if 5000 means client will send heart beat every 5000ms
            client.heartbeat.incoming = 5000; // if 0 means client does not want to receive heartbeats from server
            // client.debug = pipe('#second');

            // Declare on_connect
            let on_connect = function (x) {
                console.log('-----connected !!!!!')
                console.log("开始订阅 /event")
                that.subscribe = client.subscribe("/event/"+that.schoolId, function (d) {
                console.log("receive")
                    console.log(JSON.parse(d.body),'消息')
                    that.$api.webApi.getEventDetail(JSON.parse(d.body).id).then(res=>{
                        console.log(res)
                        if(res.code == 200){
                          that.$bus.$emit('busEvent', { message: res.data })
                            //如果弹窗存在就不覆盖数据
                            // if(!that.eventAlarm){
                            //     that.$refs.audioPlayer.play();
                            //     that.eventAlarm = true
                            //     that.rowDetail = res.data
                            //      res.data.deviceName = res.data.device.name
                            // }
                            // that.eventAllList.unshift(res.data)
                            // that.$forceUpdate();
                        }
                    })
                },function(res){
                    console.log('订阅失败',res)
                })
            };

            // Declare on_error
            let on_error = function () {
                console.log('on_error ',new Date())
                // console.log('error');
                if(that.subscribe != null){
                    that.subscribe.unsubscribe()
                    that.subscribe = null
                }
                if(that.scoket != null){
                    that.scoket.disconnect()
                    that.scoket = null
                }
              
                window.setTimeout(()=>{
                    console.log('我即将开始重连')
                    that.init()
                },1000*30)
            };
            client.connect({}, on_connect, on_error);
        },
  },
  beforeDestroy(){
    this.$EventBus.$off('busEvent');
    if(this.scoket != null){
        this.scoket.disconnect()
        this.scoket = null
    }
    if(this.subscribe != null){
        this.subscribe.unsubscribe()
        this.subscribe = null
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  position: relative;
}
/* 自定义整个滚动条 */
::-webkit-scrollbar {
  width: 6px; /* 设置滚动条的宽度 */
  height: 6px;
  
}
/* 修改滚动条轨道背景色 */
::-webkit-scrollbar-track {
  background-color: #fff;
}
 
/* 修改滚动条滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #eaeaea;
  border-radius: 10px;
  cursor: pointer;
}
 
/* 修改滚动条滑块悬停时的颜色 */
::-webkit-scrollbar-thumb:hover {
  background-color: #eaeaea;
}


</style>
