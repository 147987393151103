/*
 * @Author: your name
 * @Date: 2024-03-27 09:03:50
 * @LastEditTime: 2024-08-12 17:55:37
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \web_BullyingPrevention\src\http\axios.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import axios from 'axios'
import config from './config'
import router from '@/router'
import { Message } from 'element-ui'
// import { MessageBox } from 'element-ui'

// import {ActionContext as store} from "vuex";

// 使用vuex做全局loading时使用
// import store from '@/store'

export default function $axios (options) {
  return new Promise((resolve, reject) => {
    let baseUrl = sessionStorage.getItem('baseUrl')
    let useLocalBaseUrl = false
    if (baseUrl && baseUrl.startsWith('http')) {
      useLocalBaseUrl = true
    }
    config.headers['router'] = router.history.current.path
    const instance = axios.create({
      baseURL: useLocalBaseUrl ? baseUrl : config.baseUrl,
      headers: config.headers,
      timeout: config.timeout,
      withCredentials: config.withCredentials
    })
    // request 拦截器
    instance.interceptors.request.use(
      config => {
        // let token = Cookies.get('token')
        let token = sessionStorage.getItem('token')
        // console.log("拉取token");
        // if (token === 'device') {
        //   return config
        // }

      //  带上token
        if (token && config.url !== 'login') {
          config.headers.Authorization = token
        } else {
          // 重定向到登录页面
          // router.push('/login')
          console.log('has no token, login')
        }
        // 3. 根据请求方法，序列化传来的参数，根据后端需求是否序列化
        if (config.method === 'post') {
         
        }
        return config
      },

      error => {
        // 请求错误时
        console.log('request:', error)
        // 1. 判断请求超时
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
          console.log('timeout请求超时')
          // return service.request(originalRequest);// 再重复请求一次
        }
        // 2. 需要重定向到错误页面
        const errorInfo = error.response
        console.log(errorInfo)
        if (errorInfo) {
          error = errorInfo.data // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
          const errorStatus = errorInfo.status // 404 403 500 ...
          router.push({
            path: `/error/${errorStatus}`
          })
        }
        return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    )

    // response 拦截器
    instance.interceptors.response.use(
      response => {
        // console.log('response:', response)
        if(response.data.code && response.data.code != 200){
          console.error("有报错接口或异常接口：" + response.config.url)
          console.error("报错信息为：" + response.data.msg)
        }
        let data
        // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
        if (response.data === undefined) {
          data = JSON.parse(response.request.responseText)
        } else {
          data = response.data
        }

        // 根据返回的code值来做不同的处理
        switch (data.rc) {
          case 1:
            console.log(data.msg)
            break
          case 0:
            break
          default:
            break
        }
        // 若不是正确的返回code，且已经登录，就抛出错误
        // const err = new Error(data.desc)
        // err.data = data
        // err.response = response
        // throw err

        return data
      },
      err => {
        if (err && err.response) {
          switch (err.response.status) {
            case 400:
              err.message = '请求错误'
              break
            case 401:
              sessionStorage.removeItem('user')
              err.message = '登录信息过期，请重新登陆'
              // err.message = err.response.data.message
              router.push('/login')
              break
            case 403:
              err.message = err.response.data.msg
              // err.message = '拒绝访问'
              break
            case 404:
              err.message = `请求地址出错: ${err.response.config.url}`
              router.push('/404')
              break
            case 408:
              err.message = '请求超时'
              break
            case 500:
              err.message = '服务器内部错误'
              break
            case 501:
              err.message = '服务未实现'
              break
            case 502:
              err.message = '网关错误'
              break
            case 503:
              err.message = '服务不可用'
              break
            case 504:
              err.message = '网关超时'
              break
            case 505:
              err.message = 'HTTP版本不受支持'
              break
            default:
          }
        }
        console.error(err)
        Message({
          showClose: true,
          message: err.message,
          // type: 'error',
          type: 'error',
          duration: 1000
        })
        return Promise.reject(err) // 返回接口返回的错误信息
      }
    )

    // 请求处理
    instance(options).then(res => {
      if (res.msg) {
        switch (res.code) {
          case 10003: // token已过期
            sessionStorage.removeItem('user')
            location.reload();
            router.push('/')
            break
          case 401:  //token已过期
            sessionStorage.clear();
            location.reload()
            router.push('/')
            break
          default:
        }
      }
      resolve(res)
      return false
    }).catch(error => {
      reject(error)
    })
  })
}
