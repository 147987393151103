/*
 * @Author: your name
 * @Date: 2024-03-27 09:09:05
 * @LastEditTime: 2024-03-27 09:09:18
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \web_BullyingPrevention\src\http\index.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
// 导入所有接口
import api from './api'
// import dict from '../utils/const.js'

const install = Vue => {
  if (install.installed) {
    return
  }

  install.installed = true

  Object.defineProperties(Vue.prototype, {
    // 注意，此处挂载在 Vue 原型的 $api 对象上
    $api: {
      get () {
        return api
      }
    },
    // $dict: {
    //   get () {
    //     return dict
    //   }
    // }
  })
}

export default install
