/*
 * @Author: your name
 * @Date: 2024-07-08 16:24:48
 * @LastEditTime: 2024-07-09 09:50:13
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \web_BullyingPrevention\src\http\moudules\county.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import axios from '../axios'
//查询教育局下的下的所有事件
export const getallEvent = (data) => {
    return axios({
      url: '/area/event',
      method: 'post',
      data
    })
  }
  //查询学校下的所有位置
  export const getAllSchool = (schoolId) => {
    return axios({
      url: `/area/getAllSchool?schoolId=${schoolId}`,
      method: 'get',
    })
  }
    //查询学校下的乡镇
  export const getAllTown = (schoolId) => {
      return axios({
        url: `/area/getAllTown?schoolId=${schoolId}`,
        method: 'get',
      })
  }
  //获取学校的数量
  export const schoolDeviceCount = (schoolId) => {
      return axios({
        url: `/area/schoolDeviceCount?schoolId=${schoolId}`,
        method: 'get',
      })
  }
  //获取区县的学生数量
  export const studentCount = (schoolId) => {
      return axios({
        url: `/area/studentCount?schoolId=${schoolId}`,
        method: 'get',
      })
  }
  //获取区县事件趋势
  export const eventTrend = (schoolId) => {
    return axios({
        url: `/area/eventTrend?schoolId=${schoolId}`,
        method: 'get',
      })
  }
    //学校事件类型
    export const eventSchoolType = (schoolId) => {
      return axios({
          url: `/area/eventSchoolType?schoolId=${schoolId}`,
          method: 'get',
        })
    }
     //学校数量排行榜
     export const schoolEventRank = (schoolId,count) => {
      return axios({
          url: `/area/schoolEventRank?schoolId=${schoolId}&count=${count}`,
          method: 'get',
        })
    }

       //获取镇下面的学校
       export const schoolByTownId = (schoolId,townId) => {
        return axios({
            url: `/area/schoolByTownId?schoolId=${schoolId}&townId=${townId}`,
            method: 'get',
          })
      }
      //导出查询记录
      
      export const getallEventExport = (data) => {
        return axios({
          url: '/area/export',
          method: 'post',
          data
        })
      }