/*
 * @Author: your name
 * @Date: 2024-03-12 20:23:12
 * @LastEditTime: 2024-08-15 15:37:07
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \web_BullyingPrevention\src\main.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import echarts from 'echarts'
Vue.prototype.$echarts = echarts //挂载到Vue实例上面 

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/components/globalStyle.css'
Vue.use(ElementUI);


import api from './http'
Vue.use(api)

import vueAudioNative from 'vue-audio-native'
Vue.use(vueAudioNative)

Vue.config.productionTip = false

import moment from 'moment';
Vue.filter("filterName", function(value) {
    // 返回处理后的值
    return moment(new Date(value)).format('YYYY年MM月DD日 HH:mm') // 示例：将文本转换为大写
});
new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  }
}).$mount('#app')
