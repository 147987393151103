/*
 * @Author: your name
 * @Date: 2023-12-21 10:49:46
 * @LastEditTime: 2024-08-31 15:14:33
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \web_BullyingPrevention\src\http\config.js
 * 可以输入预定的版权声明、个性签名、空行等
 */

// import { baseUrl } from '@/utils/global'
console.log(window.location.href)
let url = process.env.VUE_APP_BASE_API
if(window.location.href && Object.entries(window.location.href).length != 0){
  let URL =  window.location.href
  let urlArr =URL.split('?');
  console.log(urlArr)
  if(urlArr.length == 1){
    //没有携带参数，需要重置schoolId
    let id = sessionStorage.getItem('schoolId')
    if(id){
      sessionStorage.setItem('schoolId',sessionStorage.getItem('schoolId'))
    }
    if( sessionStorage.getItem('api')){
      url= 'https://api.qingzhou.link/fblv2'
      // url= 'http://192.168.1.188:9080'
    }

  }else{
    let params  = new URLSearchParams(urlArr[1])

    if(params.get('schoolId') != null){
      sessionStorage.setItem('schoolId',params.get('schoolId'))
    }else{
      let id = sessionStorage.getItem('schoolId')
      if(id){
        sessionStorage.setItem('schoolId',sessionStorage.getItem('schoolId'))
      }
    }
    if(params.get('api') != null || sessionStorage.getItem('api')){
      url= 'https://api.qingzhou.link/fblv2'
      // url= 'http://192.168.1.188:9080
      sessionStorage.setItem('api',true)
    }
    if(params.get('appId') != null ){
      sessionStorage.setItem('appId',params.get('appId'))
    }
    
    if(params.get('token') != null ){
      let arr = params.get('token').split(' ')
      sessionStorage.setItem('token',arr[1])
    }
    console.log(url)
  }
}
export default {
    method: 'get',
    // 基础url前缀
    baseUrl: url, // api 的 base_url
    nodeEnv: process.env.NODE_ENV,
    // 请求头信息
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': sessionStorage.getItem('token')
    },
    // 参数
    data: {},
    // 设置超时时间
    timeout: 2000000,
    // 携带凭证（暂时关闭，为了支持SpringBoot后端跨域）
    // 扩展阅读：https://blog.csdn.net/liyuling52011/article/details/80013725，https://blog.csdn.net/luoyong123456/article/details/51306227
    withCredentials: false,
    // 返回数据类型
    responseType: 'json',
   
  }
  