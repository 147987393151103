/*
 * @Author: your name
 * @Date: 2024-03-27 09:10:00
 * @LastEditTime: 2024-03-27 09:10:14
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \web_BullyingPrevention\src\http\api.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import * as webApi from './moudules/webApi'
import * as county from './moudules/county'
export default {
    webApi,
    county
}