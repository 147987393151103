/*
 * @Author: your name
 * @Date: 2024-03-12 20:23:12
 * @LastEditTime: 2024-10-12 10:16:00
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \web_BullyingPrevention\src\router\index.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: '主页',
    component: ()=>import('../views/webView/main.vue'),
    children:[
      {
        path:'eventRecord',
        name:'事件管理',
        component:()=>import('../views/webView/components/eventRecord.vue')
      },
     {
        path:'deviceManage',
        name:'设备管理',
        component:()=>import('../views/webView/components/devicesManage.vue')
      },
      {
        path:'advancedConfig',
        name:'高级配置',
        component:()=>import('../views/webView/components/advancedConfig.vue')
      }
    ]
  },
  {
    path: '/index',
    name: '区县平台',
    component: ()=>import('../views/countyWebView/main.vue'),
    children:[
      {
        path:'eventRecord',
        name:'事件记录',
        component:()=>import('../views/countyWebView/components/eventRecord.vue')
      },
    ]
  },
  {
    path: '/countyScreenView',
    name: '数据大屏',
    component: ()=>import('../views/countyScreenView/index.vue'),
  },
  {
    path: '/indexXingJi',
    name: '辛集区县平台',
    component: ()=>import('../views/xingJiCounty/countyWebView/main.vue'),
    children:[
      {
        path:'eventRecord',
        name:'事件记录',
        component:()=>import('../views/xingJiCounty/countyWebView/components/eventRecord.vue')
      },
    ]
  },
  {
    path: '/countyScreenViewXingJi',
    name: '辛集数据大屏',
    component: ()=>import('../views/xingJiCounty/countyScreenView/indexXInJi.vue'),
  },
  {
    path: '/screenView',
    name: '数据大屏',
    component: ()=>import('../views/screenView/index.vue'),
  },
  {
    path: '/weChat',
    name: '微信小程序',
    component: ()=>import('../views/weChatView/main.vue'),
    children:[
      {
        path: 'index',
        name: '防霸凌系统',
        component: ()=>import('../views/weChatView/eventManage/index.vue'),
      },
      {
        path: 'devicesManage',
        name: '防霸凌系统',
        component: ()=>import('../views/weChatView/devicesManage/index.vue'),
      },
     
    ]
  },
  {
    path:'/deviceAdd',
    name:'设备添加',
    component:()=>import('../views/weChatView/devicesManage/deviceAdd.vue')
  },
  {
    path: '/eventHandle',
    name: '详情',
    component: ()=>import('../views/weChatView/eventManage/eventHandle.vue'),
  },
  {
    path: '/eventHandleDetail',
    name: '事件处理',
    component: ()=>import('../views/weChatView/eventManage/eventHandleDetail.vue'),
  },
  {
    path: '/eventDetail',
    name: '事件详情',
    component: ()=>import('../views/weChatView/eventManage/eventDetail.vue'),
  },
  {
    path: '/selectPeople',
    name: '选择核实人',
    component: ()=>import('../views/weChatView/eventManage/components/selectPeople.vue'),
  },
  {
    path: '/andioChat',
    name: '对讲',
    component: ()=>import('../components/andioChat.vue')
  },
  {
    path: '/loginWeb',
    name: '登录',
    component: ()=>import('../views/phoneManage/index.vue')
  },
  {
    path: '/login',
    name: '登录',
    component: ()=>import('../views/index.vue')
  },
  {
    path: '/content',
    name: '管理平台',
    component: ()=>import('../views/phoneManage/content.vue'),
    children:[
      {
        path: 'phoneManage',
        name: '号码管理',
        component: ()=>import('../views/phoneManage/phoneManage.vue')
      },
      {
        path: 'deviceMigration',
        name: '设备迁移',
        component: ()=>import('../views/phoneManage/deviceMigration.vue')
      },
      {
        path: 'devicesManage',
        name: '设备管理',
        component: ()=>import('../views/phoneManage/devicesManage.vue')
      },
      {
        path: 'advancedConfig',
        name: '设备详情',
        component: ()=>import('../views/phoneManage/advancedConfig.vue')
      }
    ]
  },
 
]

//路由重复跳转处理
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})
// 设置全局前置守卫
router.beforeEach((to, from, next) => {
  // 可以在这里进行路由守卫的逻辑处理
  // 例如：身份验证、权限校验、加载数据等
 
  // 确保要调用next()来resolve这个钩子
  if(from.path != '/login' && from.path != '/'){
    sessionStorage.setItem('fromPath',from.path);
  }
  console.log(to,from);
  next();
});
export default router
